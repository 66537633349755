import PropTypes from "prop-types";
import React from "react";

// INIT

const propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired
};
const defaultProps = {
	className: ""
};

// HELPER

const getLogo = (props) => {
	const name = props.name.toLowerCase();

	let logoProps = {
		svgContent: (
			<g></g>
		),
		viewBox: "0 0 24 24"
	};

	if(props.height) {
		logoProps.height = props.height;
	}

	if(props.width) {
		logoProps.width = props.width;
	}

	switch(name) {
		case "copdate":
			logoProps.height = "24px";
			logoProps.svgContent = (
				<g
					fill="#000"
					fillRule="nonzero"
					>
					<path
						d="M0 12C0 4.834 5.578 0 12.955 0c6.177 0 9.284 3.547 10.696 6.748l-6.142 2.817c-.6-1.808-2.436-3.374-4.554-3.374-3.424 0-5.718 2.574-5.718 5.809 0 3.235 2.294 5.809 5.718 5.809 2.118 0 3.953-1.566 4.554-3.374l6.142 2.783C22.275 20.313 19.132 24 12.955 24 5.578 24 0 19.13 0 12zM32 4.112c0 2.272-1.869 4.114-4.174 4.114-2.306 0-4.175-1.842-4.175-4.114S25.52 0 27.826 0C30.13 0 32 1.84 32 4.112z"
					/>
				</g>
			);
			logoProps.viewBox = "0 0 32 24";
		break;
	}

	return logoProps;
};

// COMPONENT

const Logo = (props) => {
	const {
		svgContent,
		...svgProps
	} = getLogo(props);

	return (
		<svg
			className={props.className}
			xmlns="http://www.w3.org/2000/svg"
			{...svgProps}
			>
			{svgContent}
		</svg>
	);
};

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

// EXPORT

export default Logo;
