import {
	transparentize
} from "polished";

import {
	colors
} from "./styled-variables";

// INIT

const setFont = (family, size, weight, height, color, style = false) => {
	let fontStyle = "";

	if(style) {
		fontStyle = `font-style: ${style};`;
	}

	return `
		font-family: ${family};
		font-size: ${size};
		font-weight: ${weight};
		line-height: ${height};
		color: ${color};
		${fontStyle}
	`;
};

const setPosition = (position, options = {}) => {
	let positionOptions = [];

	for(let key in options) {
		positionOptions.push(`${key}: ${options[key]};`);
	}

	return `
		position: ${position};
		${positionOptions.join("")}
	`;
};

// EXPORT

export {setFont};
export {setPosition};
