import {
	createGlobalStyle
} from "styled-components";

import { 
	setFont
} from "./styled-mixins";
import {
	typo
} from "./styled-variables";

// INIT

const globalStyles = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
	}
	
	html {
		height: -webkit-fill-available;
	}

	body {
		display: flex;
		margin: 0;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0;
		width: 100vw;
		min-height: 100vh;
		min-height: -webkit-fill-available;
		-webkit-tap-highlight-color: transparent;
		-webkit-font-smoothing: antialiased;
	}

	form {
		width: 100%;
	}

	#dashboard {
		display: flex;
		min-height: 100vh;
		min-height: -webkit-fill-available;
		overflow: hidden;
		width: 100%;
		-webkit-overflow-scrolling: touch;
	}

	${(props) => {
		const {
			body,
			typoColors
		} = props.theme;

		return `
			body {
				background-color: ${body.background};
				${setFont(
					typo.fontFamilies.openSans,
					typo.fontSizes.default,
					400,
					typo.lineHeight.default,
					typoColors.default
				)}
			}

			strong {
				font-weight: 600;
			}

			small {
				font-size: ${typo.fontSizes.small};
			}

			a {
				color: ${typoColors.link};
				cursor: pointer;
				text-decoration: none;
			}
		`;
	}}
`;

// EXPORT

export default globalStyles;
