import PropTypes from "prop-types";
import React from "react";

import {
	ComponentRoot,
	LabelWrapper,
	LoaderWrapper,
	RowWrapper
} from "./Button.styled";

import LoaderAnimation from "../LoaderAnimation";

// INIT

const propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.node.isRequired,
	loaderActive: PropTypes.bool,
	loaderEnable: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	paletteColor: PropTypes.oneOf([
		"dark",
		"disabled"
	])
};
const defaultProps = {
	disabled: false,
	loaderActive: false,
	loaderEnable: false,
	paletteColor: "default"
};

// COMPONENT

const Button = (props) => {
	const render = () => {
		const {
			label,
			...componentRootProps
		} = props;

		let loaderContent;

		if(props.loaderEnable) {
			loaderContent = (
				<LoaderWrapper>
					<LoaderAnimation
						className="loaderAnimation"
					/>
				</LoaderWrapper>
			);
		}

		return (
			<ComponentRoot
				{...componentRootProps}
				>
				{loaderContent}
				<LabelWrapper>
					{label}
				</LabelWrapper>
			</ComponentRoot>
		);
	};

	return render();
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

// EXPORT

export default Button;
