import i18n from "../i18n";

// METHODS

/**
 * formatCurrency - ...
 * 
 * @param {string} value Value to format
 */
export const formatCurrency = (value) => {
	if(!isNumber(value)) {
		return;
	}

	return value.toLocaleString("en-US", {
		currency: "USD",
		currencyDisplay: "symbol",
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
		style: "currency"
	});
};

/**
 * parseCurrency - ...
 * 
 * @param {mized} value Value to check
 */
export const parseCurrency = (value) => {
	let parsedValue = parseInt(String(value).replace(/[^0-9]+/g, ""));

	if(isNaN(parsedValue)) {
		parsedValue = 0;
	}

	parsedValue = String(parsedValue);

	switch(parsedValue.length) {
		case 0:
			parsedValue = "0";
		break;
		case 1:
			parsedValue = `0.0${parsedValue}`;
		break;
		case 2:
			parsedValue = `0.${parsedValue}`;
		break;
		default:
			const pos = parsedValue.length - 2;

			parsedValue = `${parsedValue.substr(0, pos)}.${parsedValue.substr(pos)}`;
		break;
	}

	return parseFloat(parsedValue);
};








/**
 * capitalizeFirstLetter - Capitalize first letter of a string.
 * 
 * @param {string} value Value to format
 */
export const capitalizeFirstLetter = (value) => {
	if(!isString(value)) {
		return;
	}

	return value.charAt(0).toUpperCase() + value.slice(1);
};



/**
 * deepCopy - Deep copy arrays & objects.
 * 
 * @param {mixed} value Value to copy
 */
export const deepCopy = (value) => {
	return JSON.parse(JSON.stringify(value));
};



/**
 * formatPercentage - Format percentage.
 * 
 * @param {string} value Value to format
 */
export const formatPercentage = (value) => {
	if(!isNumber(value)) {
		return;
	}

	return Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
};

/**
 * formatPhoneNumber - Format phone number.
 * 
 * @param {string} value Value to format
 */
export const formatPhoneNumber = (value) => {
	if(!value) {
		return "";
	}

	let formattedValue = parseStringDigits(value);

	if(formattedValue[0] === "1") {
		formattedValue = formattedValue.slice(1);
	}
	
	const area = formattedValue.substring(0, 3);
	const first3 = formattedValue.substring(3, 6);
	const last4 = formattedValue.substring(6, 10);

	if(!first3) {
		return area;
	}

	formattedValue = `(${area}) ${first3}`;

	if(last4) {
		formattedValue += `-${last4}`;
	}

	return formattedValue;
};

// validate ? here on in form.js ... do we 



const parsePatterns = {
	digit: /[^0-9]+/g
};

export const validationPatterns = {
	email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
	postalCode: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
	url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
	zipCode: /^\d{5}(?:[-\s]\d{4})?$/,
	password: /^(?=.*[A-Z])(?=.*[\W]{0,})(?=.*[0-9])(?=.*[a-z]).{8,}$/,
	phoneNumber: /^\d{10}$/
};

/**
 * arrayDeepCopy - ...
 * 
 * @param {array} array Array to clone
 */
export const arrayDeepCopy = (array) => {
	return JSON.parse(JSON.stringify(array));
};

/**
 * formatPostalCode - Format postal code.
 * 
 * @param {string} value Value to format
 */
export const formatPostalCode = (value) => {
	if(!value) {
		return "";
	}

	const parsedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");

	const area = parsedValue.substring(0, 3);
	const unit = parsedValue.substring(3, 6);

	if(!unit) {
		return area;
	}

	return `${area} ${unit}`;
};

/**
 * formatZipCode - Format zip code.
 * 
 * @param {string} value Value to format
 */
export const formatZipCode = (value) => {
	if(!value) {
		return "";
	}

	const parsedValue = value.toUpperCase().replace(/[^0-9]/g, "");

	const first5 = parsedValue.substring(0, 5);
	const extra4 = parsedValue.substring(5, 9);

	if(!extra4) {
		return first5;
	}

	return `${first5}-${extra4}`;
};

/**
 * inArray - Check if value is found within an array.
 * 
 * @param {mized} value Value to check
 * @param {mized} array Array to check against
 */
export const inArray = (value, array) => {
	return (array.includes(value));
};

/**
 * isBoolean - Check if value is a boolean.
 * 
 * @param {mized} value Value to check
 */
export const isBoolean = (value) => {
	return typeof value === "boolean";
};

/**
 * isArray - Check if value is an array.
 * 
 * @param {mized} value Value to check
 */
export const isArray = (value) => {
	//Array.isArray(doc)
	return (!!value) && (value.constructor === Array);
};

/**
 * isArrayEmpty - Check if value is an empty array.
 * 
 * @param {mized} value Value to check
 */
export const isArrayEmpty = (value) => {
	if(!isArray(value)) {
		return true;
	}

	return value.length ? false : true;
};

/**
 * isFunction - Check if value is a function.
 * 
 * @param {mized} value Value to check
 */
export const isFunction = (value) => {
	return typeof value === "function";
};

/**
 * isNumber - Check if value is a number.
 * 
 * @param {mized} value Value to check
 */
export const isNumber = (value) => {
	return typeof value === "number" && isFinite(value);
};

/**
 * isObject - Check if value is a literal object.
 * 
 * @param {mized} value Value to check
 */
export const isObject = (value) => {
	return (!!value) && value !== null && (value.constructor === Object);
};

/**
 * isObjectEmpty - Check if a literal object is empty.
 * 
 * @param {object} value Value to check
 */
export const isObjectEmpty = (value) => {
	if(!isObject(value)) {
		return true;
	}

	return Object.keys(value).length === 0;
};

/**
 * isPositiveInt - Check if a value is a positive integer.
 * 
 * @param {mixed} value Value to check
 */
export const isPositiveInt = (value, allowZero = false) => {
	if(allowZero) {
		return Number.isInteger(value) && Number(value) >= 0;
	}

	return Number.isInteger(value) && Number(value) > 0;
};

/**
 * isString - Check if value is a string.
 * 
 * @param {mixed} value Value to check
 */
export const isString = (value) => {
	return typeof value === "string";
};

/**
 * parseStringDigits - Parsing digits from string.
 * 
 * @param {string} value Value to parse
 */
export const parseStringDigits = (value) => {
	if(!value) {
		return;
	}
	
	return String(value.replace(parsePatterns.digit, ""));
};
