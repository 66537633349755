console.log("apiHelper", "complete parseCatchError");
console.log("apiHelper", "implement sentry");

import * as Sentry from "@sentry/browser";

import {
	copdateApiConfig
} from "../configs/core";

import {
	isObject,
	isObjectEmpty
} from "../helpers/core";

import i18n from "../i18n";

//import sentryService from "./js/services/sentry";

// METHODS

/**
 * getApiUrl - ...
 */
const getApiUrl = () => {
	return copdateApiConfig.url;
};

/**
 * parseCatchError - Parse catch error.
 * 
 * @param {object} rawError Error object to parse
 */
const parseCatchError = (rawError) => {
	rawError = isObject(rawError) ? rawError : {};

	let captureException = false;
	let error = {};
	let response = rawError.response || {};

	if(!isObjectEmpty(response.data)) {
		error = response.data;
	}

	if(captureException) {
		error.sentryEventId = Sentry.captureException(response);
	}

	return error;
};

// EXPORT

export default {
	getApiUrl,
	parseCatchError
};
