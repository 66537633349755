import styled from "styled-components";

import {
	columnWrapper,
	rowWrapper
} from "../../styled/styled-extends";
import {
	typo
} from "../../styled/styled-variables";

export const Body = styled.div`
`;

export const ComponentRoot = styled.div`
	border: 1px solid transparent;
	border-radius: 8px;
	max-width: 480px;
	overflow: hidden;
	width: 100%;
	${columnWrapper}

	${(props) => {
		const {
			card,
			typoColors
		} = props.theme;

		return `
			background-color: ${card.background};
			border-color: ${card.border};
			box-shadow: ${card.shadow};

			${Body},
			${Footer},
			${Header} {
				align-items: center;
				padding: 24px;
				text-align: center;
				${columnWrapper}

				> strong {
					color: ${typoColors.level2};
					font-size: ${typo.fontSizes.large};
				}

				p {
					padding-top: 8px;
				}
			}

			${Footer} {
				border-color: ${card.footer.border};
			}

			${Header} {
				background-color: ${card.header.background};
				border-color: ${card.header.border};
			}
		`;
	}};
`;

export const Footer = styled.div`
	border-top: 1px solid transparent;
`;

export const Header = styled.div`
	border-bottom: 1px solid transparent;
`;
