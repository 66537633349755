import {
	transparentize
} from "polished";

import {
	colors
} from "./styled-variables";

// INIT

const themes = {
	default: {
		backgroundColors: {
			level1: colors.black,
			level2: colors.white
		},
		body: {
			background: colors.mercury
		},
		card: {
			background: colors.white,
			border: colors.silver,
			footer: {
				border: colors.alto
			},
			header: {
				background: colors.wildSand,
				border: colors.alto
			},
			shadow: `0 4px 8px 0 ${transparentize(0.9, colors.black)}`
		},
		checkbox: {
			backgroundColors: {
				checked: colors.black,
				default: colors.white
			},
			borderColors: {
				checked: colors.black,
				default: colors.silver
			}
		},
		header: {
			background: colors.black,
			foreground: colors.white
		},
		image: {
			background: colors.wildSand
		},
		loader: {
			border: colors.silver
		},
		paletteColors: {
			dark: {
				background: colors.black,
				foreground: colors.white
			},
			default: {
				background: colors.silver,
				foreground: colors.white
			},
			disabled: {
				background: colors.silver,
				foreground: colors.white
			}
		},
		typoColors: {
			default: colors.boulder,
			error: colors.flamingo,
			level1: colors.black,
			level2: colors.mineShaft,
			level3: colors.tundora,
			level4: colors.doveGrey,
			level5: colors.boulder,
			level6: colors.dusty,
			level7: colors.nobel,
			level8: colors.alto,
			level9: colors.white,
			link: colors.azureRadiance
		}
	}
};

// EXPORT

export {themes};
