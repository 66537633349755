import PropTypes from "prop-types";
import React from "react";

import {
	Body,
	ComponentRoot,
	Footer,
	Header
} from "./Card.styled";

// INIT

const propTypes = {
	className: PropTypes.string
};
const defaultProps = {};

// COMPONENTS

const Card = (props) => {
	const render = () => {
		let componentProps = {};

		if(props.className) {
			componentProps.className = props.className;
		}

		return (
			<ComponentRoot
				{...componentProps}
			>
				{props.children}
			</ComponentRoot>
		);
	};

	return render();
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

const CardBody = (props) => {
	const render = () => {
		let bodyProps = {};

		if(props.className) {
			bodyProps.className = props.className;
		}

		return (
			<Body
				{...bodyProps}
			>
				{props.children}
			</Body>
		);
	};

	return render();
};

CardBody.propTypes = propTypes;
CardBody.defaultProps = defaultProps;

const CardFooter = (props) => {
	const render = () => {
		let footerProps = {};

		if(props.className) {
			footerProps.className = props.className;
		}

		return (
			<Footer
				{...footerProps}
			>
				{props.children}
			</Footer>
		);
	};

	return render();
};

CardFooter.propTypes = propTypes;
CardFooter.defaultProps = defaultProps;

const CardHeader = (props) => {
	const render = () => {
		let headerProps = {};

		if(props.className) {
			headerProps.className = props.className;
		}

		return (
			<Header
				{...headerProps}
			>
				{props.children}
			</Header>
		);
	};

	return render();
};

CardHeader.propTypes = propTypes;
CardHeader.defaultProps = defaultProps;

// EXPORT

export default Card;
export {CardBody};
export {CardFooter};
export {CardHeader};
