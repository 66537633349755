import styled from "styled-components";

import {
	rowWrapper
} from "../../styled/styled-extends";
import {
	setPosition
} from "../../styled/styled-mixins";

export const CheckboxWrapper = styled.div`
	${rowWrapper}
	align-items: center;
	border: 1px solid transparent;
	border-radius: 999px;
	height: 24px;
	justify-content: center;
	transition: background-color 200ms ease;
	width: 24px;
	will-change: background-color;
	//...
		background-color: #ccc;//#fff;
		border: 1px solid #ccc;
	
	span {
			background-color: #fff;
		border-radius: 999px;
			opacity: 1;
		transition: opacity 200ms ease;
		will-change: opacity;
		width: 12px;
		height: 12px;
	}
`;

export const ComponentRoot = styled.div`
	${rowWrapper}
	align-self: flex-start;
	${setPosition("relative")}

	input {
		appearance: none;
		opacity: 0;
		${setPosition("absolute", {
			"top": 0,
			"left": 0,
			"z-index": -1
		})}
	}

	${(props) => {
		const {
			backgroundColors,
			checkbox
		} = props.theme;

		let backgroundColor = checkbox.backgroundColors.default;
		let borderColor = checkbox.borderColors.default;
		let opacity = 0;

		if(props.checked) {
			backgroundColor = checkbox.backgroundColors.checked;
			borderColor = checkbox.borderColors.checked;
			opacity = 1;
		}

		return `
			${CheckboxWrapper} {
				background-color: ${backgroundColor};
				border-color: ${borderColor};

				span {
					background-color: ${backgroundColors.level2};
					opacity: ${opacity};
				}
			}
		`;
	}}
`;
