import PropTypes from "prop-types";
import React from "react";

import {
	ComponentRoot
} from "./LoaderAnimation.styled";

// INIT

const propTypes = {
	className: PropTypes.string
};
const defaultProps = {};

// COMPONENT

const LoaderAnimation = (props) => {
	const render = () => {
		let componentProps = {};

		if(props.className) {
			componentProps.className = props.className;
		}

		return (
			<ComponentRoot
				{...componentProps}
			/>
		);
	};

	return render();
};

LoaderAnimation.propTypes = propTypes;
LoaderAnimation.defaultProps = defaultProps;

// EXPORT

export default LoaderAnimation;
