import styled from "styled-components";

import {
	loaderWrapper
 } from "../../styled/styled-extends";
 import { 
	setPosition
} from "../../styled/styled-mixins";

export const ComponentRoot = styled.div`
	height: 100%;
	overflow: hidden;
	width: 100%;
	${setPosition("relative")}

	img {
		display: block;
		height: auto;
		min-height: 101%;
		max-width: 101%;
		object-fit: cover;
		overflow: hidden;
		pointer-events: none;
		transform: translate3d(-50%, -50%, 0);
	    width: auto;
		${setPosition("relative", {
			left: "50%",
			top: "50%"
		})}	
	}

	.loaderAnimation {
		height: 24px;
		width: 24px;
	}

	${(props) => {
		const {
			image
		} = props.theme;

		let loaderOpacity = 1;

		if(props.lazyLoaded) {
			loaderOpacity = 0;
		}

		return `
			${LoaderWrapper} {
				background-color: ${image.background};
				opacity: ${loaderOpacity};
			}
		`;
	}}
`;

export const LoaderWrapper = styled.div`
	${loaderWrapper}
`;
