import {
	CardElement,
	useElements,
	useStripe
} from "@stripe/react-stripe-js";
import React, {
	useEffect,
	useState
} from "react";
import ReactGA from "react-ga4";
import {
	withTranslation
} from "react-i18next";

import {
	Body,
	BoostWrapper,
	ComponentRoot,
	Header,
	LoaderWrapper,
	ReservationOverlayWrapper,
	StoreOverlayWrapper
} from "./App.styled";

import useApi from "../../api/apiHook";

import Button from "../../components/Button";
import Card, {
	CardBody,
	CardFooter,
	CardHeader
} from "../../components/Card";
import Img from "../../components/Img"; 
import InputCheckbox from "../../components/InputCheckbox";
import LoaderAnimation from "../../components/LoaderAnimation";
import Logo from "../../components/Logo";

import {
	formatCurrency,
	isObjectEmpty
} from "../../helpers/core";

import i18n from "../../i18n";
import {
	renderTrans
} from "../../i18n/i18nHelper";

import GlobalStyles from "../../styled/styled-globals";

// COMPONENT

const App = (props) => {
	const [boostToken, setBoostToken] = useState();
	const [card, setCard] = useState(null);
	const [error, setError] = useState("");
	const [isFormValidated, setIsFormValidated] = useState(false);
	const [isRenderReady, setIsRenderReady] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [paymentError, setPaymentError] = useState("");
	const [reservation, setReservation] = useState({});
	const [selectedBoost, setSelectedBoost] = useState("");
	const [showTerms, setShowTerms] = useState(false);

	const {
		fetchApi
	} = useApi();
	const elements = useElements();
	const stripe = useStripe();
	
	// Lifecycle

	useEffect(() => {
		const qs = new URLSearchParams(window.location.search);
		const token = qs.get("boostToken");

		setBoostToken(token);

		if(token) {
			fetchApi(
				{
					method: "get",
					params: {
						boost_token: token,
						service: "getEventBoost"
					},
					path: "/"
				},
				(response) => {
					if(response.success) {
						let newReservation = {
							confirmationCode: response.payload.event.confirmation_code,
							event: {
								id: response.payload.event.id
							},
							product: {
								image: response.payload.event.product.image,
								name: response.payload.event.product.name,
								sizeType: null
							},
							store: {
								address: response.payload.store.address,
								city: response.payload.store.city,
								logo: response.payload.store.logo,
								name: response.payload.store.name,
								state: response.payload.store.state
							},
							user: {
								name: `${response.payload.user.first_name} ${response.payload.user.last_name}`
							}
						};

						newReservation.boosts = response.payload.event.boost_options.map((boostItem) => {
							return {
								id: boostItem.boost_id.toString(),
								name: boostItem.name,
								price: formatCurrency(boostItem.price)
							};
						});

						if(response.payload.event.product.size) {
							newReservation.product.sizeType = response.payload.event.product.size.type;

							if(newReservation.product.sizeType === "shoe") {
								newReservation.product.sizeType = "footwear";
							}

							switch(newReservation.product.sizeType) {
								case "time":
									newReservation.product.timeSlot = response.payload.event.product.size.slot || null;
								break;
								case "apparel":
								case "footwear":
									newReservation.product.size = response.payload.event.product.size.size || null;
									newReservation.product.gender = response.payload.event.product.size.gender || null;
								break;
							}
						}

						ReactGA.send({
							hitType: "pageview", 
							page: `/?boostToken=${token}`, 
							title: `VIEW - ${newReservation.store.name} - ${newReservation.event.id}`
						});

						setReservation(newReservation);
					} else {
						if(response.error) {
							switch(response.error.code) {
								case 141: // Boost already confirmed
									setError("tokenAlreadyUse");
								break;
								case 142: // Invalid boostToken
								case 143: // ?
								case 144: // ?
									setError("tokenInvalid");
								break;
								case 145: // ?
									setError("eventClosed");
								break;
								default:
									setError("unexpectedError");
								break;
							}

							setIsRenderReady(true);
						}
					}
				}
			);
		} else {
			setError("tokenNotFound");
			setIsRenderReady(true);
		}
    }, []);

	useEffect(() => {
		if(!selectedBoost || card) {
			return;
		}

		const ce = elements.getElement(CardElement);
		ce.on("change", cardChangeHandler);

		setCard(ce);
	}, [selectedBoost]);

	useEffect(() => {
		if(isObjectEmpty(reservation)) {
			return;
		}

		setIsRenderReady(true);
    }, [reservation]);

	// Events

	const boostChangeHandler = (e) => {
		const dataBoost = e.currentTarget.getAttribute("data-boost");

		const newSelectedBoost = reservation.boosts.find((boostItem) => {
			return boostItem.id === dataBoost;
		});

		if(newSelectedBoost) {
			setSelectedBoost(newSelectedBoost);
		}
	};

	const cardChangeHandler = (e) => {
		let isValid = false;

		if(e.complete) {
			isValid = true;
		}

		setIsFormValidated(isValid);
	};

	const submitHandler = async (e) => {
		if(isSubmitting || !isFormValidated) {
			return;
		}

		setIsSubmitting(true);
		setPaymentError("");

    	const stripeRequest = await stripe.createToken(card);

		if(!stripeRequest.token) {
			if(stripeRequest.error) {
				setPaymentError(stripeRequest.error.message);
			}

			setIsFormValidated(false);
			setIsSubmitting(false);

			return;
		}

		fetchApi(
			{
				method: "get",
				params: {
					boost_option: selectedBoost.id,
					boost_token: boostToken,
					service: "payBoost",
					stripe_token: stripeRequest.token.id
				},
				path: "/"
			},
			(response) => {
				if(response.success) {
					const qs = new URLSearchParams(window.location.search);
					const token = qs.get("boostToken");

					ReactGA.send({
						hitType: "pageview", 
						page: `/?boostToken=${token}&boost=${selectedBoost.price}`, 
						title: `PAY - ${reservation.store.name} - ${reservation.event.id}`
					});

					setIsSubmitted(true);
				} else {
					if(response.error) {
						switch(response.error.code) {
							case 127: // Stripe error from backend transaction
							case 129: // Payment timeout reached
							case 130: // Stripe token is mandatory
								if(response.error.stripe_error) {
									setPaymentError(response.error.stripe_error.message);
								} else {
									setPaymentError(i18n.t(
										"notifications.error.paymentErrorWithCode",
										{
											code: response.error.code
										}
									));
								}
							break;
							default:
								setPaymentError(i18n.t("notifications.error.unexpectedError.text"));
							break;
						}

						setIsFormValidated(false);
						setIsSubmitting(false);
					}
				}
			}
		);
	};

	const termsToggleHandler = (e) => {
		setShowTerms(!showTerms);
	};

	// Render

	const render = () => {
		let bodyContent;

		if(isRenderReady) {
			if(error) {
				bodyContent = (
					<Card>
						<CardBody>
							<strong>
								{i18n.t(`notifications.error.${error}.title`)}
							</strong>
							<p>
								{i18n.t(`notifications.error.${error}.text`)}
							</p>
						</CardBody>
					</Card>
				);
			} else {
				let boostContent;
				let confirmationContent;
				let paymentContent;
				let sizeContent;
				let sizeText;
				let termsContent;

				switch(reservation.product.sizeType) {
					case "apparel":
					case "footwear":
						sizeText = i18n.t(
							`general.sizes.${reservation.product.gender}`,
							{
								size: reservation.product.size
							}
						);
					break;
					case "time":
						sizeText = reservation.product.timeSlot;
					break;
				}

				if(sizeText) {
					sizeContent = (
						<p>
							<span>
								{sizeText}
							</span>
						</p>
					);
				}

				const reservationContent = (
					<Card
						className={showTerms ? "hide" : ""}
						>
						<CardHeader
							className="reservationCardHeader"
							>
							<Img
								alt={reservation.product.name}
								className="img"
								src={reservation.product.image}
							/>
							<ReservationOverlayWrapper>
								<span>
									{reservation.user.name}
								</span>
								<strong>
									{reservation.confirmationCode}
								</strong>
								<p>
									<strong>
										{reservation.product.name}
									</strong>
								</p>
								{sizeContent}
							</ReservationOverlayWrapper>
						</CardHeader>
						<CardBody
							className="reservationCardBody"
							>
							<StoreOverlayWrapper>
								<Img
									alt={reservation.store.name}
									className="img"
									src={reservation.store.logo}
								/>
							</StoreOverlayWrapper>
							<strong>
								{reservation.store.name}
							</strong>
							<p>
								<small>
									{reservation.store.address}
								</small>
								<small>
									{`${reservation.store.city}, ${reservation.store.state}`}
								</small>
							</p>
						</CardBody>
					</Card>
				);

				if(isSubmitted) {
					confirmationContent = (
						<Card>
							<CardHeader>
								<strong>
									{i18n.t("cards.confirmation.header.title")}
								</strong>
								<p>
									{renderTrans(
										"cards.confirmation.header.text",
										{
											boostName: selectedBoost.name
										},
										{
											b: <strong />
										}
									)}
								</p>
							</CardHeader>
							<CardBody>
								{i18n.t("cards.confirmation.body.text")}
							</CardBody>
						</Card>
					);
				} else {
					const boostItems = reservation.boosts.map((boostItem) => {
						let checked = false;

						if(boostItem.id === selectedBoost.id) {
							checked = true;
						}

						return (
							<BoostWrapper
								data-boost={boostItem.id}
								key={boostItem.id}
								onClick={boostChangeHandler}
								>
								<InputCheckbox
									checked={checked}
									name={boostItem.name}
									onChange={() => {}}
									value={boostItem.id}
								/>
								<span>
									{boostItem.name}
								</span>
								<span>
									{boostItem.price}
								</span>
							</BoostWrapper>
						);
					});

					boostContent = (
						<Card
							className={showTerms ? "hide" : ""}
							>
							<CardHeader>
								<strong>
									{i18n.t("cards.boost.header.title")}
								</strong>
								<p>
									{renderTrans(
										"cards.boost.header.text",
										{},
										{
											b: <strong />
										}
									)}
								</p>
							</CardHeader>
							<CardBody
								className="boostCardBody"
								>
								{boostItems}
							</CardBody>
							<CardFooter>
								<a
									onClick={termsToggleHandler}
									>
									{i18n.t("cards.boost.footer.moreInfo")}
								</a>
							</CardFooter>
						</Card>
					);

					if(selectedBoost) {
						let paymentErrorContent;

						if(paymentError) {
							paymentErrorContent = (
								<div
									className="error"
									>
									<small>
										{paymentError}
									</small>
								</div>
							);
						}

						paymentContent = (
							<Card
								className={showTerms ? "hide" : ""}
								>
								<CardHeader>
									<strong>
										{i18n.t("cards.payment.header.title")}
									</strong>
								</CardHeader>
								<CardBody>
									<form>
										<CardElement
											id="card-element"
										/>
										{paymentErrorContent}
									</form>
								</CardBody>
								<CardFooter>
									<small>
										{renderTrans(
											"cards.payment.footer.agreement",
											{},
											{
												a: <a
													href="https://www.copdate.com/terms-of-service"
													target="_blank"
												/>
											}
										)}
									</small>
									<Button
										className="button"
										label={i18n.t(
											"cards.payment.footer.pay",
											{
												fee: selectedBoost.price
											}
										)}
										loaderActive={isSubmitting}
										loaderEnable
										onClick={submitHandler}
										paletteColor={isFormValidated ? "dark" : "disabled"}
										type="submit"
									/>
								</CardFooter>
							</Card>
						);
					}

					if(showTerms) {
						termsContent = (
							<Card>
								<CardHeader>
									<strong>
										{i18n.t("cards.terms.header.title")}
									</strong>
								</CardHeader>
								<CardBody
									className="termsCardBody"
									>
									{renderTrans(
										"cards.terms.body.text",
										{},
										{
											b: <strong />,
											i: <em />,
											li: <li />,
											p: <p />,
											s: <small />,
											ul: <ul />
										}
									)}
								</CardBody>
								<CardFooter>
									<a
										onClick={termsToggleHandler}
										>
										{i18n.t("cards.terms.footer.close")}
									</a>
								</CardFooter>
							</Card>
						);
					}
				}

				bodyContent = (<>
					{termsContent}
					{reservationContent}
					{confirmationContent}
					{boostContent}
					{paymentContent}
				</>);
			}
		}

		return (
			<ComponentRoot
				loaderActive={!isRenderReady}
				>
				<GlobalStyles />
				<Header>
					<Logo
						name="copdate"
					/>
				</Header>
				<Body>
					{bodyContent}
					<LoaderWrapper>
						<LoaderAnimation />
					</LoaderWrapper>
				</Body>
			</ComponentRoot>
		);
	};

	return render();
};

export default withTranslation()(App);
