// INIT

const colors = {
	alto: "#DDD",
	azureRadiance: "#018FF5",
	black: "#000",
	boulder: "#757575",
	doveGrey: "#666",
	dusty: "#999",
	flamingo: "#EA5030",
	mercury: "#E5E5E5",
	mineShaft: "#222",
	nobel: "#BBB",
	silver: "#C5C5C5",
	tundora: "#444",
	white: "#FFF",
	wildSand: "#F5F5F5"
};

const indexes = {
	loader: 999
};

const typo = {
	fontFamilies: {
		openSans: `"Open Sans", sans-serif`,
		heebo: `"Heebo", sans-serif`
	},
	fontSizes: {
		default: "16px",
		huge: "40px",
		large: "18px",
		small: "14px"
	},
	lineHeight: {
		default: 1.5,
		large: 1.75,
		small: 1.25
	}
};

// EXPORT

export {colors};
export {indexes};
export {typo};
