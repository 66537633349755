import PropTypes from "prop-types";
import React, {
	useState
} from "react";

import {
	ComponentRoot,
	LoaderWrapper
} from "./Img.styled";

import LoaderAnimation from "../LoaderAnimation";

// INIT

const propTypes = {
	alt: PropTypes.string,
	className: PropTypes.string,
	src: PropTypes.string.isRequired
};
const defaultProps = {
	alt: ""
};

// COMPONENT

const Img = (props) => {
	const [lazyLoaded, setLazyLoaded] = useState(false);

	// Misc

	const imageOnLoadHandler = (e) => {
		if(!e.target) {
			return;
		}

		if(e.target.complete && e.target.naturalHeight !== 0) {
			setLazyLoaded(true);
		}
  	};

	// Render
	
	const render = () => {
		let componentProps = {};

		if(props.className) {
			componentProps.className = props.className;
		}

		return (<ComponentRoot
			lazyLoaded={lazyLoaded}
			{...componentProps}
		>
			<LoaderWrapper>
				<LoaderAnimation
					className="loaderAnimation"
				/>
			</LoaderWrapper>
			<img
				alt={props.alt}
				onLoad={imageOnLoadHandler}
				src={props.src}
			/>
		</ComponentRoot>);
	};

	return render();
};

Img.propTypes = propTypes;
Img.defaultProps = defaultProps;

// EXPORT

export default Img;
