import * as Sentry from "@sentry/browser";

import {
	inArray,
	isArray,
	isBoolean,
	isObject,
	isString
} from "../helpers/core";

let isSentry = false;
let levels = [];
let tagPrefix = "";

export const captureException = (error) => {
	/*
	Sentry.captureMessage(message, {
		clearBreadcrumbs: true,
		autoBreadcrumbs: false,
		level: "error",
		tags: { foo: "bar" }
	});
	*/
	Sentry.withScope((scope) => {
		scope.clear();

		scope.setUser({
		    id: 42,
			email: "john.doe@example.com",
		});
		
		/*
		scope.addBreadcrumb({
			type: "info",
			category: "argument",
			message: JSON.stringify({foo:{bar:"bar", doe:"doe"}}),
			level: "info"
		});
		*/
		//scope.setExtra('battery', 0.7);

		console.log(error);

		scope.setTag("_foo", "bar");
		scope.setTag("copdate_foo", "bar");

		Sentry.captureException(error);
	});
};

/**
 * captureMessage - Capture a message.
 * 
 * @param {string} level Message level
 * @param {string} message Message to capture
 * @param {object} tags List of tags
 */
export const captureMessage = (level, message = "", tags) => {
	if(!isSentry) {
		return;
	}
	/*
	Sentry.captureMessage(message, {
		clearBreadcrumbs: true,
		autoBreadcrumbs: false,
		level: "error",
		tags: { foo: "bar" }
	});
	*/
	Sentry.withScope((scope) => {
		/*
		scope.setUser({
		    id: 42,
			email: "john.doe@example.com",
		});
  		*/
		//scope.clear();
		/*
		scope.addBreadcrumb({
			type: "info",
			category: "argument",
			message: JSON.stringify({foo:{bar:"bar", doe:"doe"}}),
			level: "info"
		});
		*/
		if(inArray(level, levels)) {
			scope.setLevel(level);
		}

		if(isArray(tags)) {
			tags.map((tagItem) => {
				scope.addBreadcrumb({
					type: "info",
					category: tagItem.category,
					message: JSON.stringify(tagItem.value),
					level: "info"
				});
				//scope.setTag(tagKey, tags[tagKey]);
			});
		}

		//scope.setExtra('battery', 0.7);

		Sentry.captureMessage(message);
	});
};

/**
 * init - Initialize Sentry.
 * 
 * @param {object} sentryConfigs Sentry configs
 * @param {object} appInfo App info
 */
export const init = (sentryConfigs, appInfo) => {
	appInfo = isObject(appInfo) ? appInfo : {};
	sentryConfigs = isObject(sentryConfigs) ? sentryConfigs : {};

	if(sentryConfigs.dsn) {
		isSentry = true;

		if(isArray(sentryConfigs.levels)) {
			levels = sentryConfigs.levels;
		}

		if(isString(sentryConfigs.tagPrefix)) {
			tagPrefix = sentryConfigs.tagPrefix;
		}

		let initOptions = {
			debug: appInfo.debug || false,
			dsn: sentryConfigs.dsn
		};

		// if inArray?
		if(appInfo.env) {
			initOptions.env = appInfo.env;
		}

		if(isArray(sentryConfigs.ignoreErrors)) {
			initOptions.ignoreErrors = sentryConfigs.ignoreErrors;
		}

		if(isString(sentryConfigs.projectName) && isString(appInfo.version)) {
			initOptions.release = `${sentryConfigs.projectName}@${appInfo.version}`;
		}

		Sentry.init(initOptions);

		//logger
	} else {
		//logger
	}
};

/**
 * setTag - Set a new tag.
 * 
 * @param {string} key Tag key
 * @param {string} value Tag value
 */
export const setTag = (key, value) => {
	Sentry.setTag(`${tagPrefix}${key}`, value);
};

export default {
	captureException,
	captureMessage,
	init,
	setTag
};
