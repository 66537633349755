import React from "react";
import {
	Trans
} from "react-i18next";

/**
 * renderTrans - ...
 *
 */
export const renderTrans = (i18nKey, values, components) => {
	if(!i18nKey) {
		// [TODO] Sentry

		return;
	}

	values = values || {};
	components = components || {};

	return (<Trans
		components={components}
		i18nKey={i18nKey}
		values={values}
	/>);
};
