import React, {
	useEffect,
	useRef
} from "react";

import {
	apiRequest
} from "../api";

import {
	isFunction
} from "../helpers/core";

// HOOK

const useApi = () => {
	const mounted = useRef(false);

	// Lifecycle

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
		};
    }, []);

	// Methods

	/**
	 * fetchApi - ... 
	 */
	const fetchApi = (apiRequestOptions, callback) => {
		apiRequest(
			apiRequestOptions,
			(response) => {
				if(mounted.current) {
					if(isFunction(callback)) {
						callback(response);
					}
				}
			}
		);
	};

	// Return

	return {
		fetchApi
	};
};

// EXPORT

export default useApi;
