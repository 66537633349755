import axios from "axios";

import apiHelper from "./apiHelper";

import {
	isFunction,
	isObject
} from "../helpers/core";

// EXPORT

/**
 * apiRequest - ...
 */
export const apiRequest = (options, callback) => {
	let formattedResponse = {};

	axios({
		url: `${apiHelper.getApiUrl()}${options.path}`,
		...options
	}).then((response) => {
		const payload = isObject(response.data) ? response.data : {};

		formattedResponse = payload;
	}).catch((error) => {
		formattedResponse.error = apiHelper.parseCatchError(error);
	}).then((response) => {
		if(isFunction(callback)) {
			callback(formattedResponse);
		}
	});
};
