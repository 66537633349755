import {
	transparentize
} from "polished";
import styled from "styled-components";

import {
	columnWrapper,
	ellipsis,
	loaderWrapper,
	rowWrapper
} from "../../styled/styled-extends";
import { 
	setFont,
	setPosition
} from "../../styled/styled-mixins";
import {
	typo
} from "../../styled/styled-variables";

export const Body = styled.div`
	${columnWrapper}
	align-items: center;
	min-height: 100%;
	padding: 16px;
	${setPosition("relative")}

	> div {
		:not(:first-child) {
			margin-top: 16px;
		}
	}

	@media only screen and (min-width: 768px) {
		padding: 24px;

		> div {
			:not(:first-child) {
				margin-top: 24px;
			}
		}
	}
`;

export const BoostWrapper = styled.div`
	${rowWrapper}
	cursor: pointer;
	width: 100%;

	> * {
		:first-child {
			margin-right: 16px;
		}

		:last-child {
			flex: 1;
			text-align: right;
		}
	}
`;

export const ComponentRoot = styled.div`
	${columnWrapper}
	width: 100%;

	.boostCardBody {
		font-weight: 600;
		padding: 0 !important;

		> * {
			padding: 16px;
		
			:not(:first-child) {
				border-top: 1px solid transparent;
			}
		}
	}

	.button {
		margin-top: 24px;
		width: 100%;
	}

	.error {
		padding-top: 16px;
	}

	.hide {
		display: none;
	}

	.reservationCardBody {
		padding-top: 32px !important;
		${setPosition("relative")}

		p {
			${columnWrapper}
			width: 100%;

			small {
				${ellipsis}
			}
		}
	}

	.reservationCardHeader {
		padding: 0 !important;
		${setPosition("relative")}

		.img {
			height: 320px;
		}
	}

	.termsCardBody {
		align-items: flex-start !important;
		text-align: left !important;

		p {
			padding-top: 16px !important;
		}

		ul {
			padding-top: 16px;
			padding-left: 18px;
			list-style-position: outside;
		}

		li {
			display: list-item;
		}
	}

	${(props) => {
		const {
			backgroundColors,
			body,
			card,
			header,
			typoColors
		} = props.theme;

		let loaderWrapperOpacity = 0;
		let loaderWrapperPointerEvents = "none";

		if(props.loaderActive) {
			loaderWrapperOpacity = 1;
			loaderWrapperPointerEvents = "auto";
		}

		return `
			background-color: ${body.background};

			.boostCardBody {
				> * {	
					:not(:first-child) {
						border-color: ${card.header.border};
					}
				}
			}

			.error {
				color: ${typoColors.error};
			}

			.termsCardBody {
				strong {
					color: ${typoColors.level2};
				}
			}

			${Header} {
				background-color: ${header.background};

				svg {
					circle,
					path,
					rect {
						fill: ${header.foreground};
					}
				}
			}

			${LoaderWrapper} {
				background-color: ${body.background};
				opacity: ${loaderWrapperOpacity};
				pointer-events: ${loaderWrapperPointerEvents};
			}

			${ReservationOverlayWrapper} {
				background-color: ${transparentize(0.4, backgroundColors.level1)};
				color: ${typoColors.level8};

				span {
					font-weight: 600;
				}

				strong {
					color: ${typoColors.level9};
					font-size: ${typo.fontSizes.large};
				}

				> strong {
					letter-spacing: 3px;
					${setFont(
						typo.fontFamilies.heebo,
						typo.fontSizes.huge,
						500,
						typo.lineHeight.default,
						typoColors.level9
					)}
				}
			}
		`;
	}};
`;

export const Header = styled.div`
	${rowWrapper}
	align-items: center;
	justify-content: center;
	padding: 16px;
	width: 100%;
`;

export const LoaderWrapper = styled.div`
	${loaderWrapper}
	transition: opacity 800ms;
`;

export const ReservationOverlayWrapper = styled.div`
	${columnWrapper}
	align-items: center;
	height: 100%;
	justify-content: center;
	padding: 24px;
	width: 100%;
	${setPosition("absolute", {
		"left": 0,
		"top": 0,
		"z-index": 1
	})}
`;

export const StoreOverlayWrapper = styled.div`
	${columnWrapper}
	align-items: center;
	height: 48px;
	justify-content: center;
	width: 100%;
	${setPosition("absolute", {
		"left": 0,
		"top": "-24px",
		"z-index": 2
	})}

	.img {
		border-radius: 50%;
		height: 48px;	    
		width: 48px;
	}
`;
