import {
	css
} from "styled-components";

import { 
	setPosition
} from "./styled-mixins";
import {
	indexes
} from "./styled-variables";

// INIT

const columnWrapper = css`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
`;

const disabled = css`
	&.disabled,
	.disabled {
		cursor: not-allowed;
		pointer-events: none;
	}
`;

const ellipsis = css`
	overflow: hidden;
	width: 100%;
	box-sizing: border-box;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const loaderWrapper = css`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	pointer-events: none;
	transition: opacity 200ms;
	width: 100%;
	will-change: opacity;
	${setPosition("absolute", {
		"left": 0,
		"top": 0,
		"z-index": indexes.loader
	})}
`;

const rowWrapper = css`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
`;

// EXPORT

export {columnWrapper};
export {disabled};
export {ellipsis};
export {loaderWrapper};
export {rowWrapper};
