import PropTypes from "prop-types";
import React from "react";

import {
	CheckboxWrapper,
	ComponentRoot
} from "./InputCheckbox.styled";

// INIT

const propTypes = {
	checked: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	value: PropTypes.string
};
const defaultProps = {
	checked: false
};

// COMPONENT

const InputCheckbox = (props) => {
	return (
		<ComponentRoot
			checked={props.checked}
			onClick={props.onChange}
			>
			<input
				type="checkbox"	
				{...props}
			/>
			<CheckboxWrapper>
				<span></span>
			</CheckboxWrapper>
		</ComponentRoot>
	);
};

InputCheckbox.propTypes = propTypes;
InputCheckbox.defaultProps = defaultProps;

// EXPORT

export default InputCheckbox;
