import {
	Elements
} from "@stripe/react-stripe-js";
import {
	loadStripe
} from "@stripe/stripe-js";
import React, {
	Suspense
} from "react";
import ReactDOM from "react-dom";
import initReactFastclick from "react-fastclick";
import ReactGA from "react-ga4";
import {
	ThemeProvider
} from "styled-components";

import {
	appInfo,
	gaConfigs,
	sentryConfigs,
	stripeConfigs
} from "./js/configs/core";

import App from "./js/screens/App";

import sentryService from "./js/services/sentry";

import {
	themes
} from "./js/styled/styled-themes";

// INIT

initReactFastclick();

ReactGA.initialize([
	{
		gaOptions: {
			titleCase: false
		},
		gtagOptions: {},
		trackingId: gaConfigs.trackingId
	}
]);

sentryService.init(
	sentryConfigs, 
	appInfo
);

const stripePromise = loadStripe(stripeConfigs.key);

// RENDER

ReactDOM.render(
	<Suspense
		fallback={null}
		>
		<ThemeProvider
			theme={themes.default}
			>
			<Elements
				stripe={stripePromise}
				>
				<App />
			</Elements>
		</ThemeProvider>
	</Suspense>,
	document.querySelector("#dashboard")
);
