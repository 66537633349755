import styled from "styled-components";

import {
	disabled,
	loaderWrapper,
	rowWrapper
 } from "../../styled/styled-extends";
import { 
	setFont,
	setPosition
} from "../../styled/styled-mixins";
import {
	typo
} from "../../styled/styled-variables";

export const ComponentRoot = styled.div`
	${disabled}
	align-items: center;
	border-radius: 4px;	
	box-sizing: border-box;
	display: flex;
	height: 48px;
	justify-content: center;
	outline: none;
	text-align: center;
	transition: background-color 200ms, color 200ms;
	${setPosition("relative")}

	${(props) => {
		const {
			paletteColors
		} = props.theme;

		let buttonBg = paletteColors[props.paletteColor].background;
		let buttonCursor = "pointer";
		let buttonFg = paletteColors[props.paletteColor].foreground;
		let labelOpacity = 1;
		let loaderOpacity = 0;

		if(props.disabled) {
			buttonBg = paletteColors.disabled.background;
			buttonCursor = "not-allowed";
			buttonFg = paletteColors.disabled.foreground;
		}

		if(props.loaderEnable && props.loaderActive) {
			buttonCursor = "not-allowed";
			labelOpacity = 0;
			loaderOpacity = 1;
		}

		return `
			background-color: ${buttonBg};
			cursor: ${buttonCursor};

			${LoaderWrapper} {
				opacity: ${loaderOpacity};

				.loaderAnimation {
					border-top-color: ${buttonFg};
				}
			}

			${LabelWrapper} {
				opacity: ${labelOpacity};
				${setFont(
					typo.fontFamilies.heebo,
					typo.fontSizes.default,
					600,
					"normal",
					buttonFg
				)}
			}
		`;
	}};
`;

export const LabelWrapper = styled.div`
	${rowWrapper}
	align-items: center;
	justify-content: center;
	padding: 16px;
`;

export const LoaderWrapper = styled.div`
	${loaderWrapper}

	.loaderAnimation {
		height: 24px;
		width: 24px;
	}
`;
