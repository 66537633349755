import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import {
    initReactI18next
} from "react-i18next";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: false, //coreConfig.debug,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        load: "languageOnly",
        backend: {
            loadPath: "/assets/locales/en.json"
        },
        react: {
            useSuspense: true
        }
    }, (error) => {
        if(error) {
            console.log("i18next error", error);
            // manual sentry here
        }
    });

export default i18n;
