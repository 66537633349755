import i18n from "../i18n";

// EXPORT

export const appInfo = {
	debug: DEBUG,
	env: ENV,
	version: VERSION
};

export const copdateApiConfig = {
	url: API_PROXY_URL
};

export const gaConfigs = {
	trackingId: GA4_TRACKING_ID
};

export const sentryConfigs = {
	dsn: SENTRY_DSN || "",
	ignoreErrors: [],
	levels: [
		"debug",
		"error",
		"fatal",
		"info",
		"warning"
	],
	//release: `${SENTRY_PROJECT_NAME}@${VERSION}`, // No need as already set in webpack
	tagPrefix: "CD"
};

export const stripeConfigs = {
	key: STRIPE_KEY
};

export default {
	appInfo,
	copdateApiConfig,
	gaConfigs,
	sentryConfigs,
	stripeConfigs
};
