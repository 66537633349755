import styled, {
	keyframes
} from "styled-components";

const loaderAnimation = keyframes`
	from {
		transform: rotate(0deg);
  	}
	to {
		transform: rotate(360deg);
	}
`;

export const ComponentRoot = styled.div`
	animation: ${loaderAnimation} 800ms linear infinite;
	border: 2px solid transparent;
	border-radius: 50%;
	box-sizing: border-box;
	height: 48px;
	width: 48px;

	${(props) => {
		const {
			loader
		} = props.theme;

		return `
			border-top-color: ${loader.border};
		`;
	}};
`;
